import React from 'react'
import anuncio from "../../assets/Videos/anuncio.mp4"
import "../../styles/Videos/video.css"
function VidePlayC() {
    return (
        <section class="video-container">
            <video controls>
                <source src={anuncio} type="video/mp4" />
                Tu navegador no soporta el elemento de video.
            </video>
        </section>
    )
}

export default VidePlayC;