import React from "react";


import organo from '../../assets/Image/Nosotros/OrganoRector.jpg'
import EOMosaico from "../../components/Nosotros/EquipoOperativoMosaico";
//import EOMosaico from "./MiembrosEO/EOMosaico";

//import { Icon } from "@material-ui/core";
import "../../styles/General_Styles/Colors.css"
import "../../styles/Nosotros/EOperativoViewStyles.css"


function NosotrosView() {

    return (
        <div className="containerVSEO">

            <h2 className="tituloPaginaVSEO ">Acerca de Nosotros</h2>

            <section className="section2VSEO ">
                <h2 className="tituloSeccionVSEO  colortxt2VSEO tituloCentradoVSEO">¿Qué son los NODESS?</h2>
                <div className='textContainerVSEO'>
                    <p className='parrafosVSEO colortxt3VSEO'>Los nodos de impulso a la economía social
                        y solidaria (NODESS), son alianzas locales integradas de manera
                        voluntaria, con al menos tres actores institucionales interesados
                        en fomentar y desarrollar la economía social y solidaria en el
                        territorio.
                    </p>
                </div>
            </section>

            <section className="sectionVSEO colorBackground">
                <div className="tituloCentrado2VSEO">
                    <div className='textContainerVSEO'>
                        <h2 className="tituloSeccionVSEO colortxt2VSEO">Mision</h2>

                        <p className='parrafosVSEO colortxt3VSEO'>Generar estrategias y acciones que fomenten e
                            impulsen el desarrollo de prácticas y ejercicios desde la economía social
                            y solidaria, que coadyuven en la reducción de las desigualdades sociales
                            y la construcción de paz en la región Pátzcuaro-Zirahuén.

                        </p>
                    </div>
                </div>
                <div className="tituloCentrado2VSEO ">
                    <div className='textContainerVSEO'>
                        <h2 className="tituloSeccionVSEO">Vision</h2>
                        <p className='parrafosVSEO colortxt3VSEO'>Tiene como objetivo impulsar un desarrollo más
                            justo y equitativo en la región, creando oportunidades y fortaleciendo los
                            lazos comunitarios.
                        </p>
                    </div>
                </div>
            </section>

            <section className="section2VSEO">
                    <h2 className="tituloSeccionVSEO colortxt2VSEO tituloCentradoVSEO">Historia</h2>
                    <div className='textContainerVSEO'>
                        <p class="parrafosVSEO colortxt3VSEO">
                            En 2020, se estableció el NODESS Pátzcuaro-Zirahuén, integrado por las siguientes entidades:
                            <ul>
                                <li>TecNM campus Pátzcuaro</li>
                                <li>Ayuntamientos de Pátzcuaro y Quiroga</li>
                                <li>Cooperativas "Cam Mujeres" y "Chapitiro" del municipio de Pátzcuaro, Michoacán</li>
                                <li>Grupo Interdisciplinario de Tecnología Rural Apropiada A.C. (GIRA)</li>
                            </ul>
                            Además, se unieron como aliados el Ayuntamiento de Tzintzuntzan y el Centro Regional de Educación para el Desarrollo Sustentable (CREDES-SEMARNAT).
                            <br /><br />
                            Las áreas de trabajo iniciales fueron:
                            <ol>
                                <li>Investigación</li>
                                <li>Divulgación, difusión y publicación</li>
                                <li>Fortalecimiento y acompañamiento</li>
                                <li>Emprendimiento asociativo</li>
                                <li>Cultura de paz</li>
                                <li>Gestión integrada de cuencas, identidad, cultura y soberanía alimentaria</li>
                            </ol>
                        </p>


                        <p className="parrafosVSEO">
                            El NODESS Pátzcuaro-Zirahuén tiene el firme compromiso de continuar trabajando para contribuir en la creación, organización
                            y fortalecimiento de las empresas del sector de la economía social solidaria, desde el trabajo colaborativo generado entre
                            las instituciones educativas, los gobiernos locales, las organizaciones de la sociedad civil, comunidades y ciudadanía.
                        </p>

                </div>
            </section>

            <section className="sectionVSEO ">
                <div className="tituloCentradoVSEO">
                    <h2 className="tituloSeccionVSEO colortxt2VSEO tituloCentradoVSEO">Organo Rector</h2>
                    <img src={organo} alt="Imagen" className='newsImageContainerVSEO' />
                </div>
            </section>

            <EOMosaico />

        </div>
    );

}

export default NosotrosView;