import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import "../../styles/CarrouselStyles/Eventos/EventListStyles.css";

function EventList() {
    const [events, setEvents] = useState([]); // Estado para almacenar los eventos
    const [loading, setLoading] = useState(true); // Estado de carga
    const [error, setError] = useState(null); // Estado de error
    const navigate = useNavigate();

    const handleButtonClick = (id) => {
        navigate(`/event/${id}`); // Navega a la vista del evento con el ID
    };

    const truncateDescription = (description, maxLength) => {
        if (description.length > maxLength) {
            return description.slice(0, maxLength) + '...';
        }
        return description;
    };

    useEffect(() => {
        // Llamada a la API para obtener la lista de eventos
        const fetchEvents = async () => {
            try {
                const response = await axios.get('/api/evento');
                setEvents(response.data); // Guardar eventos en el estado
            } catch (error) {
                setError('Error al cargar los eventos');
            } finally {
                setLoading(false); // Finalizar carga
            }
        };

        fetchEvents();
    }, []);

    if (loading) {
        return <div>Cargando eventos...</div>;
    }

    if (error) {
        return <div>{error}</div>;
    }

    return (
        <div className='containerEventosList'>
            <h2 className="tituloPaginaNoticiaList tituloCentradoNoticiasList">Eventos</h2>

            <section className="containerEventoList2">
                {events.map((event) => (
                    <div key={event._id} className='noticias-container'>
                        <div className='news-content-img'>
                            <img src={event.imageUrl} alt="Imagen del Evento" className='newsImageNoticiasList' />
                        </div>
                        <div className='news-content-inf'>
                            <h1 className='tituloNoticiaList'>{event.titulo}</h1>
                            <p className='parrafosNoticiaList colortxt3'>
                                {truncateDescription(event.descripcion, 241)}
                            </p>
                            <p className='parrafoFNoticiaList'>Fecha: {new Date(event.fecha).toLocaleDateString()}</p>
                            <div className='buttonContainerNoticiaList'>
                                <button
                                    className='bnNLC bnNL'
                                    onClick={() => handleButtonClick(event._id)}
                                >
                                    Leer más...
                                </button>
                            </div>
                        </div>
                    </div>
                ))}
            </section>
        </div>
    );
}

export default EventList;
