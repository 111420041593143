import React from 'react'

import CooperativasC from '../../components/Carrousels/Cooperativas/CooperativasC'
import "../../styles/CarrouselStyles/Alianzas/AlianzaStyles.css";

function CooperativasView() {
  return (

    <section className='sectionAlianza'>
      <div className="tituloCentradoAlianza">
        <h2 className="tituloPaginaAlianza tituloCentradoAlianza colortxtAS">Cooperativas</h2>
      </div>
      <CooperativasC />
    </section>
  )
}

export default CooperativasView