import React from 'react'
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../../styles/CarrouselStyles/ImgCarrousel/ImgCarrousel.css"
import img1 from "../../../assets/Image/Inicio/Imagen1.jpg"
import img2 from "../../../assets/Image/Inicio/imagen2_1.jpg"

function ImgCarrousel() {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true, // Habilita la reproducción automática
        autoplaySpeed: 3000, // Establece la velocidad de cambio en milisegundos (ejemplo: 3000 ms = 3 segundos)
    };

   
    return (
            <section className='containerCarrusel'>
                <Slider {...settings}>
                    <div className="newsItem">
                        <img
                            src={img1}
                            alt="Imagen de la noticia"
                            className="newsImageContainer" />
                    </div>
                    <div className="newsItem">
                        <img
                            src={img2}
                            alt="Imagen de la noticia"
                            className="newsImageContainer" />
                    </div>
                </Slider>
            </section>
    );
}


export default ImgCarrousel;