import React, { useEffect, useState } from 'react';
import { Link, Outlet } from 'react-router-dom';
import axios from 'axios';
import "../../styles/CarrouselStyles/LineasEstrategicasC/LineasEstViewStyles.css";

function LineasEstrategicasView() {
  const [lineas, setLineas] = useState([]);

  useEffect(() => {
    const fetchLineas = async () => {
      try {
        const response = await axios.get('/api/lineas'); // Cambia la URL según sea necesario
        setLineas(response.data);
      } catch (error) {
        console.error('Error al obtener las líneas estratégicas:', error);
      }
    };

    fetchLineas();
  }, []);

  return (
    <div className='containerLEV'>
      <h2 className='tituloPaginaLEV tituloCentradoLEV'>Líneas Estratégicas</h2>

      <div className="menu-container">
        <ul className="menu-list">
          {lineas.map(linea => (
            <li key={linea._id}>
              <Link to={`/LineasEstrategicas/${linea._id}`}>{linea.nombre}</Link>
            </li>
          ))}
        </ul>
      </div>

      <Outlet />
    </div>
  );
}

export default LineasEstrategicasView;
