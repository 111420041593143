// EOMosaico.jsx
/*import React from "react";
import { useNavigate } from 'react-router-dom';
import MemberInfo from "../../context/Nosotros/MemberInfo"; // Asegúrate de que la ruta sea correcta

/** Imports Styles */
/*import "../../styles/Nosotros/EOperativoMosaico.css";

function EOMosaico() {
    const navigate = useNavigate();

    const handleButtonClick = (id) => {
        navigate(`/member/${id}`);
    };

    return (
        <div className="containerEOM">
            <section className="sectionEOM color3EOM">
                <div className="tituloCentradoEOM">
                    <h2 className="tituloPaginaEOM ">Equipo Operativo</h2>
                </div>
                <div className="sectionEO">
                    {MemberInfo.map((member) => (
                        <button
                            key={member.id}
                            className='bnCard bnCardColor cardEO'
                            onClick={() => handleButtonClick(member.id)}
                        >
                            <img src={process.env.PUBLIC_URL + '/assets/Image/Nosotros/Integrantes/' + member.image} alt="Imagen" className='iconCard' />
                            <div className='overLay-textCard'>
                                <h2 className="card-title">{member.name}</h2>
                                <p className="card-description">{member.description}</p>
                            </div>
                        </button>
                    ))}
                </div>
            </section>
        </div>
    );
}

export default EOMosaico;*/
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import "../../styles/Nosotros/EOperativoMosaico.css";

function EOMosaico() {
    const [miembros, setMiembros] = useState([]); // Estado para los miembros
    const navigate = useNavigate();

    useEffect(() => {
        // Función para obtener todos los miembros desde el servidor
        const fetchMiembros = async () => {
            try {
                const response = await axios.get('/api/equipo');
                setMiembros(response.data); // Actualizar el estado con los miembros
            } catch (error) {
                console.error('Error al obtener los miembros:', error);
            }
        };

        fetchMiembros();
    }, []);

    const handleClick = (id) => {
        navigate(`/member/${id}`);
    };

    return (
        <div className='containerEOM'>
            <div className='sectionEOM color3EOM'>
                <div className="tituloCentradoEOM">
                    <h2 className="tituloPaginaEOM">Equipo Operativo</h2>
                </div>

                <div className='sectionEO'>
                    {miembros.map((miembro) => (
                        <button key={miembro._id} className='bnCard bnCardColor cardEO' onClick={() => handleClick(miembro._id)}>
                            <img src={miembro.imageUrl} alt={`Imagen de ${miembro.nombre}`} className='iconCard' />
                            <div className='overLay-textCard'>
                                <h2 >{miembro.nombre}</h2>
                                <p >{miembro.carrera}</p>
                            </div>
                        </button>
                    ))}
                </div>
            </div>
        </div>


    );
}

export default EOMosaico;
