import React from 'react'

import AlianzasList from '../../components/Carrousels/Alianzas/AlianzasList'
import "../../styles/CarrouselStyles/Alianzas/AlianzaStyles.css";

function AlianzasView() {
  return (
    <section className='sectionAlianza' >
      <div className="tituloCentradoAlianza">
        <h2 className="tituloPaginaAlianza tituloCentradoAlianza colortxtAS"> Alianzas</h2>
      </div>
      <AlianzasList />
    </section>
  )
}

export default AlianzasView