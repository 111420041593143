//import React from 'react';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';


/**Imports Styles */
import "../../../styles/CarrouselStyles/Alianzas/AlianzaStyles.css";

/**Imports Data*/
//import cooperativasData from '../../../context/Cooperativas/CooperativasData';

function CooperativasC() {
    const [osses, setOsses] = useState([]);
    const navigate = useNavigate();


    useEffect(() => {
        const fetchCooperativas = async () => {
            try {
                const response = await axios.get('/api/osses');
                setOsses(response.data);
            } catch (error) {
                console.error('Error al obtener las Osses:', error);
            }
        };

        fetchCooperativas();
    }, []);

    return (
        <section className="sectionAlianza">

            <p className='parrafosAlianzas colortxt3'>
                Las OSSES, siglas que significan "Organismos de la Sociedad Civil de Interés Público", son instituciones no gubernamentales que tienen un interés en mejorar y contribuir al desarrollo social y comunitario. Estas organizaciones suelen estar compuestas por ciudadanos que comparten un objetivo común en áreas como la educación, la salud, el medio ambiente, el desarrollo económico, entre otros.
            </p>

            <div className='contenedor-padreAlianza'>
               {/* {osses.map((oss) => (*/}
               {/* Renderiza un mensaje si no hay alianzas */}
               {osses.length === 0 ? (
                    <p className="mensajeError">No hay alianzas disponibles por el momento.</p>
                ) : (
                    osses.map((oss) => (
                    <div key={oss._id} className='imageSubContainerAlianza color9 bn37'>

                        <div className='imageCardContainerAlianza'>
                            <img src={oss.imageUrl} alt="Imagen de Osses" className='iconAlianza' />
                        </div>

                        <div className="card-contentAlianza">
                            <h3 className='titulocardAlianza colortxtAS'>{oss.titulo}</h3>
                            <p className='parrafosAlianzas colortxt3'>{oss.descripcion}</p>
                        </div>

                        <button className='bn3637Alianza bn37Alianza' onClick={() => window.location.href = oss.link}>Ver más...</button>
                    </div>
                ))
            )}
            </div>
        </section>

    )
}

export default CooperativasC;
