import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Slider from "react-slick"; // Importa Slider de react-slick

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "../../styles/CarrouselStyles/Noticias/NoticiaDetail.css"

function NoticiaDetail() {
    const { id } = useParams();
    const [news, setNews] = useState(null);

    useEffect(() => {
        const fetchNoticia = async () => {
            try {
                const response = await axios.get(`/api/noticia/${id}`);
                setNews(response.data);
            } catch (error) {
                console.error('Error al obtener la noticia:', error);
            }
        };

        fetchNoticia();
    }, [id]);

    if (!news) {
        return <div>Noticia no encontrada</div>;
    }

    // Configuración del slider
    const sliderSettings = {
        dots: true,
        infinite: news.imageUrls.length > 1, // Solo es infinito si hay más de una imagen
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: news.imageUrls.length > 1,
        autoplaySpeed: 2000,
        arrows: news.imageUrls.length > 1, // Solo muestra flechas si hay más de una imagen
    };

    return (
        <div className='containerNDetail color5'>
            <section className="noticia-detallada">
                <h1 className='tituloNDetail'>{news.titulo}</h1>

                {/* Slider de imágenes */}
                <Slider {...sliderSettings} className='newsImageContainer'>
                    {news.imageUrls.map((image, index) => (
                        <div key={index}>
                            <img src={image} alt={`Imagen ${index + 1}`} className='newsImageNDetail' />
                        </div>
                    ))}
                </Slider>

                <div className="contenido">
                    <p className='parrafosNA'>{news.descripcion}</p>
                </div>

                <div className="creditos-autor">
                    <p className="autor">Escrito por: {news.autor}</p>
                    <p className="fecha-publicacion">Fecha de Publicación: {new Date(news.fecha).toLocaleDateString()}</p>
                    <p className="derechos">Todos los derechos reservados &copy; 2024</p>
                </div>
                 <div className='buttonContainerNoticiaList'>
                <button className='bn3637Alianza bn37Alianza' onClick={() => window.location.href = news.links}>Conocer más...</button>
                </div>
            </section>
        </div>
    );
}

export default NoticiaDetail;
