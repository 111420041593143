import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import NavBar from "../src/components/Header/NavBar"
import Inicio from "./views/Inicio/inicio";
import Footer from "../src/components/Footer/Footer";
import NosotrosView from "./views/Nosotros/NosotrosView";
import EOMosaico from "./components/Nosotros/EquipoOperativoMosaico";
import MemberDetails from "./views/Nosotros/MiembrosEO/MemberDetails";
import AlianzasView from "./views/Alianzas/AlianzasView";
import CooperativasView from "./views/Cooperativas/CooperativasView";
import NewsList from "./views/Noticia/NoticiasViewList";
import NoticiaDetail from "./views/Noticia/NoticiaViewDetail";
import EventList from "./views/Eventos/EventList";
import EventDetail from "./views/Eventos/EventDetail";
import LineasEstrategicasView from "./views/LineasEstrategicas/LineasEstrategicasView";
//import CulturaPazView from "./views/LineasEstrategicas/CulturaPazView";
import LineaEstrategicaView from './views/LineasEstrategicas/LineasEstrategicaView';
//import DivulgacionDifView from "./views/LineasEstrategicas/DivulgacionDifView"
//import EstrategiaFortView from "./views/LineasEstrategicas/EstrategiaFortView";
//import EmprendimientosColectView from "./views/LineasEstrategicas/EmprendimientosColectView";
//import InvestigacionView from "./views/LineasEstrategicas/InvestigacionView";
import ContactanosView from "./views/Contactanos/ContactanosView";

function App() {

  return (
    <div className="">
      <Router>
        <NavBar />
        <Routes>
          <Route index element={<Inicio />} />
          <Route path="/inicio" element={<Inicio />} />
          <Route path="/Nosotros" element={<NosotrosView />} />
          <Route path="/" element={<EOMosaico />} />
          <Route path="/member/:id" element={<MemberDetails />} />
          <Route path="/Alianzas" element={<AlianzasView />} />
          <Route path="/Cooperativas" element={<CooperativasView />} />
          <Route path="/NoticiasList" element={<NewsList />} />
          <Route path="/news/:id" element={<NoticiaDetail />} />
          <Route path="EventosList" element={<EventList />} />
          <Route path="/event/:id" element={<EventDetail />} />
          <Route path="/LineasEstrategicas" element={<LineasEstrategicasView />}>
            <Route index element={<div>Seleccione una línea estratégica</div>} />
            <Route path=":lineaId" element={<LineaEstrategicaView />} />
          </Route>
          <Route path="Contactanos" element={<ContactanosView />} />

        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;