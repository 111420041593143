import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import "../../../styles/Nosotros/EOperativoDetailsStyles.css";

function MemberDetails() {
    const { id } = useParams(); // Obtener el ID desde la URL
    const [member, setMember] = useState(null); // Estado para almacenar el miembro

    useEffect(() => {
        // Función para obtener el miembro por ID desde el servidor
        const fetchMember = async () => {
            try {
                const response = await axios.get(`/api/equipo/${id}`);
                setMember(response.data);
            } catch (error) {
                console.error('Error al obtener el miembro:', error);
            }
        };

        fetchMember();
    }, [id]);

    if (!member) {
        return <div>Miembro no encontrado</div>; // Manejo de error
    }

    return (
        <div className='containerEOD'>
            <h2 className="tituloPaginaEOD tituloCentradoEOD">Sobre mí</h2>
            <section className='sectionEOD'>
                <div className='imageContainerEOD'>
                    <img src={member.imageUrl} alt={`Imagen de ${member.nombre}`} className='newsImageEOD' />
                    <p className='memberNameEOD'>{member.nombre}</p>
                    <div className='academicBackgroundContainerEOD'>
                        <h3 className="tituloSeccionEOD">Formación Académica</h3>
                        <p className='justifiedTextEOD parrafosEOD'>{member.formacion}</p>
                    </div>
                </div>
                <div className='textContainerEOD'>
                    <h2 className="tituloSeccionEOD">Logros</h2>
                    <p className='justifiedTextEOD parrafosEOD'>{member.logros}</p>
                    <h2 className="tituloSeccionEOD">Experiencias</h2>
                    <p className='justifiedTextEOD parrafosEOD'>{member.experiencias}</p>
                    <div>
                        <h2 className="tituloSeccionEOD">Contacto</h2>
                        <p className='justifiedTextEOD parrafosEOD'>{member.correo}</p>
                        <p className='justifiedTextEOD parrafosEOD'>{member.telefono}</p>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default MemberDetails;
