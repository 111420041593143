import React, { useEffect, useState } from 'react';

/**Imports Styles */
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../../../styles/CarrouselStyles/Colaboradores/ColabStyles.css';

const NColaboradores = () => {
    const [colaboradores, setColaboradores] = useState([]);

    useEffect(() => {
        // Llamada a la API para obtener colaboradores desde la base de datos
        fetch('/api/colaborador')
            .then(response => response.json())
            .then(data => setColaboradores(data))
            .catch(error => console.error('Error fetching colaboradores:', error));
    }, []);

    return (
        <div className='sectionCS'>
            <h2 className="tituloPagina tituloCentrado color2ColabS">Nuestros Colaboradores</h2>
            <div className="carousel-container">
                {colaboradores.map((colaborador, index) => (
                    <div key={index} className="carousel-item">
                        <a href={colaborador.link} target="_blank" rel="noopener noreferrer">
                            <img src={colaborador.imageUrl} alt={`Logo ${colaborador.nombre}`} className="carousel-image" />
                        </a>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default NColaboradores;
