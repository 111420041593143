import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import { useNavigate } from 'react-router-dom';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import axios from 'axios';
import "../../../styles/CarrouselStyles/Eventos/EventCarrouselStyles.css";

function EventCarrousel() {
    const [events, setEvents] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();
{/*
    useEffect(() => {
        const fetchEvents = async () => {
            try {
                const response = await axios.get('/api/evento');
                setEvents(response.data); // Guardar eventos en el estado
            } catch (error) {
                setError('Error al cargar los eventos');
            } finally {
                setLoading(false);
            }
        };

        fetchEvents();
    }, []);*/}
    useEffect(() => {
        const fetchEvents = async () => {
            try {
                const response = await axios.get('/api/evento');
                console.log(response.data); // Verificar estructura de datos
                setEvents(response.data); 
            } catch (error) {
                console.error('Error al cargar los eventos:', error);
                setError('Error al cargar los eventos');
            } finally {
                setLoading(false);
            }
        };
    
        fetchEvents();
    }, []);
    

    const handleButtonClick = (id) => {
        navigate(`/event/${id}`);
    };

    const settings = {
        dots: true,
        infinite: events.length > 1,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000
    };

    if (loading) {
        return <div>Cargando eventos...</div>;
    }

    if (error) {
        return <div>{error}</div>;
    }

    return (
        <div className="bannerContainerCarrousel">
            <h1 className="tituloPaginaBanner tituloCentradoBanner colortxt2Banner">Eventos</h1>
            {events.length > 0 ? (
                <Slider {...settings} className="slider">
                    {/*{events.map((event, index) => (*/}
                    {Array.isArray(events) && events.map((event, index) => (
                        <div key={index} className="cardContainerBanner">
                            <article className='cardEventBanner'>
                                <img src={event.imageUrl} alt="Imagen del Evento" className='imgEventCarrousel' />
                                <div className="container-envelopeBanner">
                                    <h2 className='tituloEventCarrouselBanner'>{event.titulo}</h2>
                                    <p className='parrafoFEventCarrousel'>Fecha: {new Date(event.fecha).toLocaleDateString()}</p>
                                    <div className='buttonContainerBanner'>
                                        <button className='bnEL bnELC' onClick={() => handleButtonClick(event._id)}>
                                            Conocer más...
                                        </button>
                                    </div>
                                </div>
                            </article>
                        </div>
                    ))}
                </Slider>
            ) : (
                <div className="noEventsContainer">
                    <p className="noEventsMessage">No hay eventos disponibles en este momento.</p>
                </div>
            )}
        </div>
    );
}

export default EventCarrousel;
