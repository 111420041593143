import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios'; // Añadido Axios para la solicitud al servidor

import "../../styles/CarrouselStyles/Noticias/NoticiasListStyles.css";

function NewsList() {
    const [news, setNews] = useState([]); // Estado para almacenar las noticias
    const navigate = useNavigate();

    const handleButtonClick = (id) => {
        navigate(`/news/${id}`);
    };

    const truncateDescription = (description, maxLength) => {
        if (description.length > maxLength) {
            return description.slice(0, maxLength) + '...';
        }
        return description;
    };

    // Obtener las noticias del servidor
    useEffect(() => {
        const fetchNoticias = async () => {
            try {
                const response = await axios.get('/api/noticia'); // Solicitud GET a tu API de noticias
                const sortedNews = response.data.sort((a, b) => new Date(b.fecha) - new Date(a.fecha)); // Ordenar las noticias por fecha
                setNews(sortedNews); // Actualiza el estado con las noticias ordenadas
            } catch (error) {
                console.error('Error al obtener las noticias:', error);
            }
        };

        fetchNoticias();
    }, []);

    return (
        <div className='containerNoticiasList'>
            <h2 className="tituloPaginaNoticiaList tituloCentradoNoticiasList">Noticias Nodess</h2>
            <section className="containerNoticiasList2">
                {news.map((newsItem, index) => (
                    <div key={index} className='noticias-container'>
                        <div className='news-content-img'>
                            <img src={newsItem.imageUrls[0]} alt="Imagen de la Noticia" className='newsImageNoticiasList' />
                        </div>
                        <div className='news-content-inf'>
                            <h1 className='tituloNoticiaList'>{newsItem.titulo}</h1>
                            <p className='parrafosNoticiaList colortxt3'>
                                {truncateDescription(newsItem.descripcion, 241)}
                            </p>
                            <p className='parrafoFNoticiaList'>Fecha: {new Date(newsItem.fecha).toLocaleDateString()}</p>
                            <div className='buttonContainerNoticiaList'>
                                <button
                                    className='bnNLC bnNL'
                                    onClick={() => handleButtonClick(newsItem._id)}
                                >
                                    Leer más...
                                </button>
                                
                            </div>
                        </div>
                    </div>
                ))}
            </section>
        </div>
    );
}

export default NewsList;
