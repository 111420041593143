import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import axios from 'axios'; // Añadido Axios
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useNavigate } from 'react-router-dom';

// Importar estilos
import "../../../styles/CarrouselStyles/Noticias/NoticiasCarrouselStyles.css";
import "../../../styles/General_Styles/Colors.css";
import "../../../styles/General_Styles/Containers.css";

function NoticiaCarrousel() {
    const [news, setNews] = useState([]); // Estado para almacenar las noticias
    const navigate = useNavigate();

    const handleButtonClick = (id) => {
        navigate(`/news/${id}`);
    };

    // Obtener noticias desde la API
    useEffect(() => {
        const fetchNoticias = async () => {
            try {
                const response = await axios.get('/api/noticia'); // Solicitud GET a la API de noticias
                setNews(response.data); // Actualizar el estado con las noticias
            } catch (error) {
                console.error('Error al obtener las noticias:', error);
            }
        };

        fetchNoticias();
    }, []);

    const settings = {
        dots: true,
        infinite: news.length > 1, // Solo true si hay más de un elemento
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000
    };

    return (
        <div className="sectionNoticiasCarrousel">
            <h1 className="tituloPaginaCarrousel tituloCentradoCarrousel colortxt2">Últimas Noticias</h1>
            <Slider {...settings}>
            {Array.isArray(news) && news.map((newsItem, index) => (
                    <article className="newsItemCarrousel" key={index}>
                        <div className="newsImageContainerCarrousel">
                            <div className="newsImageOverlayCarrousel">
                                <img src={newsItem.imageUrls[0]} alt="Imagen de la Noticia" className="newsImageCarrousel" />
                            </div>
                            <div className="newsTextContainerCarrousel">
                                <h3 className="tituloNoticiaCarrousel colortxt2">{newsItem.titulo}</h3>
                                <p className="parrafoFCarrousel">Fecha: {new Date(newsItem.fecha).toLocaleDateString()}</p>
                                <div className="buttonContainerCarrousel">
                                    <button
                                        className="bnNC bnNTC"
                                        onClick={() => handleButtonClick(newsItem._id)}
                                    >Leer Más...</button>
                                </div>
                            </div>
                        </div>
                    </article>
                ))}
            </Slider>
        </div>
    );
}

export default NoticiaCarrousel;
