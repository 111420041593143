import React from 'react';
import "../../styles/FooterStyles/FooterStyles.css";


function Footer() {
    return (
        <footer className="footer">
            <div className="container">
                <div className="row">

                    <div className="footer-col">
                        <h4>NODESS</h4>
                        <ul>
                            <li><a href="./inicio">Inicio</a></li>
                            <li><a href="./nosotros">Nosotros</a></li>
                            <li><a href="./noticias">Noticias</a></li>
                            <li><a href="./LineasEstrategicas">Lineas Estratégicas</a></li>
                            <li><a href="./Alianzas">Alianzas</a></li>
                            <li><a href="./Osses">OSSES</a></li>
                        </ul>
                    </div>

                    <div className="footer-col">
                        <h4>Síguenos</h4>
                        <div className="social-links">
                            <a href="https://www.facebook.com/profile.php?id=100050519454292"><i className="fab fa-facebook-f"></i></a>
                            <a href="https://www.instagram.com/patzcuarozirahuen/"><i className="fab fa-instagram"></i></a>
                        </div>
                    </div>

                    <div className="footer-col">
                        <h4>Contáctanos</h4>
                        <ul>
                            <li><a href="./inicio">Inicio</a></li>
                            <li><a href="./nosotros">Nosotros</a></li>
                            <li><a href="./noticias">Noticias</a></li>
                            <li><a href="./LineasEstrategicas">Lineas Estratégicas</a></li>
                            <li><a href="./Alianzas">Alianzas</a></li>
                            <li><a href="./Osses">OSSES</a></li>
                            <li><a href="./Contactanos">Contáctanos</a></li>
                        </ul>
                    </div>

                </div>
            </div>
        </footer>
    );
}

export default Footer;
