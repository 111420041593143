import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../styles/NavBarStyles/NavBarStyles.css";

const links = [
    { name: "Inicio", href: "/Inicio" },
    { name: "Nosotros", href: "/Nosotros" },
    { name: "Noticias", href: "/NoticiasList" },
    { name: "Eventos", href: "/EventosList" },
    { name: "Lineas Estrategicas", href: "/LineasEstrategicas" },
    { name: "Alianzas", href: "/Alianzas" },
    { name: "Cooperativas", href: "/Cooperativas" },
    { name: "Contactanos", href: "/Contactanos" },
];

const NavBar = () => {
    const [menuOpen, setMenuOpen] = useState(false);

    // Función para cerrar el menú al hacer clic en un enlace
    const handleLinkClick = () => {
        setMenuOpen(false); // Cierra el menú
    };

    return (
        <nav>
            <Link to="/Inicio" className="logo">
                <img
                    src="/logoNodess.png"
                    alt="Logo"
                    className="logoImage"
                />
            </Link>

            <div className="menu" onClick={() => setMenuOpen(!menuOpen)}>
                <span></span>
                <span></span>
                <span></span>
            </div>

            <ul className={menuOpen ? "open" : ""}>
                {links.map((x, index) => (
                    <li key={index}>
                        <NavLink to={x.href} onClick={handleLinkClick}>
                            {x.name}
                        </NavLink>
                    </li>
                ))}
            </ul>
        </nav>
    );
};

export default NavBar;
