import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

/**Styles Imports */
import "../../../styles/CarrouselStyles/LineasEstrategicasC/LECarrouselStyles.css";

const settingsCards = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3, // Mostrar tres cartas a la vez
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    vertical: false, // Configura el carrusel para desplazarse horizontalmente
    responsive: [
        {
            breakpoint: 844, // Cambiar el número de cartas a mostrar en pantallas más pequeñas
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1
            },
        },
        {
            breakpoint: 400, // Cambiar el número de cartas a mostrar en pantallas más pequeñas
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
};

export default function LECarrousel() {
    const navigate = useNavigate();
    const [lineas, setLineas] = useState([]);
    const [loading, setLoading] = useState(true);  // Estado para manejar la carga de datos
    const [error, setError] = useState(null); // Estado para manejar errores

    // Función para redireccionar a la página de cada línea estratégica
    const handleButtonClick = (id) => {
        navigate(`/LineasEstrategicas/${id}`);
    };

    // Cargar las líneas estratégicas desde la API
    useEffect(() => {
        const fetchLineas = async () => {
            try {
                const response = await axios.get('/api/lineas'); // Cambia la URL según sea necesario
                setLineas(response.data);
                setLoading(false);  // Datos cargados, cambiar el estado de carga
            } catch (error) {
                console.error('Error al obtener las líneas estratégicas:', error);
                setError('No se pudieron cargar las líneas estratégicas'); // Manejo de errores
                setLoading(false);  // Terminar el estado de carga aunque haya error
            }
        };

        fetchLineas();
    }, []);

    // Si estamos cargando o hubo un error, mostrar un mensaje adecuado
    if (loading) {
        return <div>Cargando líneas estratégicas...</div>;
    }

    if (error) {
        return <div>{error}</div>;
    }

    return (
        <section className='section2LECarrousel color3'>
            <div className="tituloCentradoLECarrousel">
                <h2 className="tituloPaginaLECarrousel colortxt6">Líneas Estratégicas</h2>
            </div>
            <div className="sectionCardsContainerLECarrousel">
                <Slider {...settingsCards}>
                    {Array.isArray(lineas) && lineas.map((linea, index) => (
                        <button 
                            key={index} 
                            className='bnCardLECarrousel bnCardColorLECarrousel cardItemLECarrousel' 
                            onClick={() => handleButtonClick(linea._id)} // Redirige a la página de la línea estratégica
                        >
                            {/* La imagen proviene de la base de datos o una fuente fija */}
                            <img 
                                src={linea.imageUrl ? linea.imageUrl : 'ruta/imagen_por_defecto.jpg'} 
                                alt={linea.nombre} 
                                className='iconCardLECarrousel'  
                            />
                            <div className='overLay-textCard'>
                                <h2 className="card-title">{linea.nombre}</h2>
                                <p className="card-description">{linea.descripcion}</p>
                            </div>
                        </button>
                    ))}
                </Slider>
            </div>
        </section>
    );
}
