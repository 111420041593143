import React from 'react';
import EOMosaico from '../../components/Nosotros/EquipoOperativoMosaico';
import "../../styles/Contactanos/ContactanosStyles.css";
import tec from "./../../assets/Image/Contactanos/tec-patz.jpg"

function ContactanosView() {
    const openMap = () => {
        window.open('https://www.google.com/maps/place/Instituto+Tecnol%C3%B3gico+Superior+De+P%C3%A1tzcuaro/@19.5410924,-101.5839984,15z/data=!4m13!1m6!3m5!2zMTnCsDMyJzIwLjIiTiAxMDHCsDM1JzA4LjYiVw!8m2!3d19.5389444!4d-101.5857222!10e5!3m5!1s0x842d96443815d9c1:0x9cc096bc1fcd5152!8m2!3d19.5410924!4d-101.5839984!16s%2Fg%2F11g889p0qs?entry=ttu', '_blank');
    };

    return (
        <div className='containerCV'>
            <h1 className="tituloPaginaCV ">Contáctanos</h1>



            <section className='section2'>
                <div>
                    <h3 className="tituloSeccionCV tituloCentrado2">Ubicación</h3>
                    <p className='pStyle' style={{ textAlign: 'center' }}>
                        <a href="#" onClick={openMap} style={{ color: 'inherit', textDecoration: 'none' }}>
                            Av. Tecnológico No. 1, Zurumútaro, Mpio. de Pátzcuaro, Mich., Carretera Pátzcuaro-Morelia, Pátzcuaro, México
                        </a>
                    </p>
                </div>
                <div className='contenedor-padre'>
                    <div className="info-contacto">
                        <h3 className="tituloSeccionCV tituloCentrado">Teléfono</h3>
                        <p className='pStyle'>434 542 50 49</p>
                    </div>

                    <div className="info-contacto">
                        <h3 className="tituloSeccionCV tituloCentrado">Gmail</h3>
                        <p className='pStyle'>
                            <a href="mailto:nodess.patz.zir@itspa.edu.mx" style={{ color: 'inherit' }}>nodess.patz.zir@itspa.edu.mx</a>
                        </p>
                    </div>

                    <div className="info-contacto">
                        <h3 className="tituloSeccionCV tituloCentrado">Facebook</h3>
                        <p className='pStyle'>
                            <a href="https://www.facebook.com/profile.php?id=100050519454292" style={{ color: 'inherit', textDecoration: 'none' }}>Nodess Pátzcuaro-Zirahuén</a>
                        </p>
                    </div>
                </div>

                <div className='contenedor-padre'>
                    <img src={tec} alt="Icono 1" className='icon' />
                </div>
            </section>

            <EOMosaico />
        </div>
    );
}

export default ContactanosView;
