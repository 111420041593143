import React, { useEffect, useState } from 'react'; 
import axios from 'axios';
import { useParams } from 'react-router-dom';
import "../../styles/CarrouselStyles/LineasEstrategicasC/LineasEstViewStyles.css";
import "../../styles/CarrouselStyles/LineasEstrategicasC/LineasEstDetailsStyles.css";
// Importa el componente Slider de react-slick
import Slider from 'react-slick';

// Importa los estilos CSS de react-slick
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

function LineaEstrategicaView() {
  const { lineaId } = useParams(); // Obtén el ID de la línea desde la URL
  const [lineaEstrategica, setLineaEstrategica] = useState(null);
  const [actividades, setActividades] = useState([]);

  // Configuración ajustada para Líneas Estratégicas
  const sliderSettings = (imageCount) => ({
    dots: imageCount > 1,               // Solo muestra puntos si hay más de una imagen
    infinite: imageCount > 1,           // Solo es infinito si hay más de una imagen
    speed: 500,                         // Velocidad de transición entre slides
    slidesToShow: 1,                    // Muestra un slide a la vez
    slidesToScroll: 1,                  // Desplaza un slide a la vez
    autoplay: imageCount > 1,           // Solo activa autoplay si hay más de una imagen
    autoplaySpeed: 2000,                // Tiempo entre cada slide en autoplay
    pauseOnHover: imageCount > 1,       // Pausa autoplay si hay más de una imagen
    arrows: imageCount > 1,             // Solo muestra flechas si hay más de una imagen
    adaptiveHeight: true,               // Ajusta la altura del carrusel según el contenido
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: imageCount > 1,         // Mantiene los puntos si hay más de una imagen
          arrows: imageCount > 1,       // Mantiene las flechas si hay más de una imagen
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,                  // No muestra puntos en pantallas pequeñas
          arrows: imageCount > 1,       // Mantiene flechas si hay más de una imagen
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,                  // No muestra puntos en pantallas muy pequeñas
          arrows: imageCount > 1,       // Mantiene flechas si hay más de una imagen
        },
      },
    ],
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Obtener la línea estratégica
        const lineaResponse = await axios.get(`/api/lineas/${lineaId}`);
        setLineaEstrategica(lineaResponse.data);

        // Obtener las actividades relacionadas con esta línea estratégica
        const actividadesResponse = await axios.get(`/api/actividades/${lineaId}`);
        setActividades(actividadesResponse.data);
      } catch (error) {
        console.error("Error fetching datos:", error);
      }
    };

    fetchData();
  }, [lineaId]);

  return (
    <div className='containerLED'>
      {lineaEstrategica ? (
        <>
          <section className='section2LED'>
            <h1 className='tituloSeccionLED'>{lineaEstrategica.nombre}</h1>
            <p className='parrafosDescripcionLED'>{lineaEstrategica.descripcion}</p>
          </section>

          {Array.isArray(actividades) && actividades.length > 0 ? (
            actividades.map((actividad) => {
              const imageCount = actividad.imageUrls ? actividad.imageUrls.length : 0; // Cuenta las imágenes
              return (
                <div key={actividad._id}> {/* Usa el identificador único de la actividad */}
                  <div className="separadorContainerLED">
                    <h1 className='tituloDerechaLED'>{actividad.titulo}</h1>
                    <div className='separadorLED'></div>
                  </div>
                  <p className='parrafosLED'>{actividad.descripcion}</p>
                  <Slider {...sliderSettings(imageCount)} className="newsImageContainerLED">
                    {actividad.imageUrls && actividad.imageUrls.map((url, index) => (
                      <div key={index}>
                        <img src={url} alt={`Actividad ${index}`} className='newsImageLED' />
                                              </div>
                    ))}
                  </Slider>
                </div>
              );
            })
          ) : (
            <p>No hay actividades disponibles.</p>
          )}
        </>
      ) : (
        <p>Cargando datos...</p>
      )}
    </div>
  );
}

export default LineaEstrategicaView;