import React from 'react'

/**Styles Imports */
import "../../styles/General_Styles/Containers.css"
import "../../styles/General_Styles/Colors.css"
import "../../styles/CarrouselStyles/Alianzas/AlianzaStyles.css";




/**Components Imports */
import ImgCarrousel from '../../components/Carrousels/ImgCarrousel/ImgCarrousel';
import VidePlayC from '../../components/Video/VidePlayC';
import LECarrousel from '../../components/Carrousels/LineasEstrategicas/LECarrousel';
import InfNodess from '../../components/InfNodessComponent/InfNodess';
import AlianzasList from '../../components/Carrousels/Alianzas/AlianzasList';
import CooperativasC from '../../components/Carrousels/Cooperativas/CooperativasC';
import NColaboradores from '../../components/Carrousels/Colaboradores/NColaboradores';
import EventList from '../../components/Carrousels/Eventos/EventCarrousel';
import NoticiaCarrousel from '../../components/Carrousels/Noticias/NoticiaCarrousel';
function Inicio() {

    return (
        <div className='containerMain'>

            <ImgCarrousel />



            <VidePlayC />

            <InfNodess />

            <LECarrousel />

            <section className="sectionAlianza">
            <div className="tituloCentradoAlianza">
                <h2 className="tituloPaginaAlianzaInicio tituloCentradoAlianza colortxtAS"> Alianzas</h2>
            </div>
                <AlianzasList />
                <button className='bn2Alianza ' onClick={() => window.location.href = '../Alianzas'}>Ver Todo...</button>
            </section>

            <section className="sectionAlianza ">
                <div className="tituloCentradoAlianza">
                    <h2 className="tituloPaginaAlianzaInicio tituloCentradoAlianza colortxtAS">Cooperativas</h2>
                </div>
                <CooperativasC />
                <button className='bn2Alianza ' onClick={() => window.location.href = '../Cooperativas'}>Ver Todo...</button>
            </section>


            <NColaboradores />

            <section className="sectionPublicaciones">

                <NoticiaCarrousel />

                <EventList />

            </section>


        </div>

    );
}





export default Inicio;