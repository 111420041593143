import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import "../../styles/CarrouselStyles/Eventos/EventDetailStyles.css";

function EventDetail() {
    const { id } = useParams(); // Obtener el id de los parámetros de la URL
    const [event, setEvent] = useState(null); // Estado para almacenar el evento
    const [loading, setLoading] = useState(true); // Estado de carga
    const [error, setError] = useState(null); // Estado de error

    useEffect(() => {
        // Llamada a la API para obtener el evento por ID
        const fetchEvent = async () => {
            try {
                const response = await axios.get(`/api/evento/${id}`);
                setEvent(response.data); // Guardar el evento en el estado
            } catch (error) {
                setError('Error al cargar el evento');
            } finally {
                setLoading(false); // Finalizar carga
            }
        };

        fetchEvent();
    }, [id]);

    if (loading) {
        return <div>Cargando evento...</div>;
    }

    if (error) {
        return <div>{error}</div>;
    }

    if (!event) {
        return <div>Evento no encontrado</div>; // Manejo de errores si el evento no se encuentra
    }

    return (
        <div className='containerNDetail color5'>
            <section className="noticia-detallada">
                <h1 className='tituloNDetail'>{event.titulo}</h1>

                <img src={event.imageUrl} alt="Imagen del Evento" className='newsImageNDetail' />
                <div className="contenido">
                    <p className='parrafosNA'>{event.descripcion}</p>
                </div>

                <div className="creditos-autor">
                    <p className="autor">Organizador(es): {event.autor}</p>
                    <p className="custom-link">Link:  <a href={event.link} target="_blank" rel="noopener noreferrer">{event.link}</a> </p>
                    <p className="fecha-publicacion">Fecha de Publicación: {new Date(event.createdAt).toLocaleDateString()}</p>
                    <p className="derechos">Todos los derechos reservados &copy; 2024</p>
                </div>
            </section>
        </div>
    );
}

export default EventDetail;
