import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';


import "../../../styles/CarrouselStyles/Alianzas/AlianzaStyles.css";


function AlianzasList() {
    const [alianzas, setAlianzas] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchAlianzas = async () => {
            try {
                const response = await axios.get('/api/alianzas');
                console.log('Respuesta de la API:', response.data); // Agregar este log
                setAlianzas(response.data);
            } catch (error) {
                console.error('Error al obtener las alianzas:', error);
            }
        };

        fetchAlianzas();
    }, []);

    return (
        <section className="sectionAlianza">

            <p className='parrafosAlianzas colortxt3'>
                Los NODESS son alianzas locales, voluntarias y autónomas, interesadas en fomentar e implementar acciones concretas de Economía Social y Solidaria (ESS) en el territorio.
                Las alianzas NODESS se integran, por lo menos, con tres actores representativos de los sectores económico social, académico y gubernamental, como lo son:
            </p>

            <div className='contenedor-padreAlianza'>
                {/*{alianzas.map((alianza) => (*/}
                {/* Renderiza un mensaje si no hay alianzas */}
                {alianzas.length === 0 ? (
                    <p className="mensajeError">No hay alianzas disponibles por el momento.</p>
                ) : (
                    alianzas.map((alianza) => (
                    <div key={alianza._id} className='imageSubContainerAlianza color9 bn37'>

                        <div className='imageCardContainerAlianza'>
                            <img src={alianza.imageUrl} alt="Imagen de la Alianza" className="iconAlianza" />
                        </div>

                        <div className="card-contentAlianza">
                            <h3 className='titulocardAlianza colortxtAS'>{alianza.titulo}</h3>
                            <p className='parrafosAlianzas colortxt3'>{alianza.descripcion}</p>
                        </div>
                        
                        <button className='bn3637Alianza bn37Alianza' onClick={() => window.location.href = alianza.link}>Ver más...</button>
                    </div>
                ))
            )}
            </div>
        </section>
    );
}

export default AlianzasList;
