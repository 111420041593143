import React from 'react'

/**Imports Styles */
import "../../styles/InfoNodess/InfNodessStyles.css"


function InfNodess() {
    return (

        <section className="sectionInfNodess">
            <div className='imageContainerInfNodess'>
                <img src="artesania.jpg" alt="Imagen" className='imageInfNodess' />

            </div>
            <div className='textContainerInfNodess'>
                <h1 className='tituloPaginaInfNodess colortxt2'>¿Qué son los NODESS?</h1>
                <p className='parrafosInfNodess'>Los nodos de impulso a la economía social
                    y solidaria (NODESS), son alianzas locales integradas de manera
                    voluntaria, con al menos tres actores institucionales interesados
                    en fomentar y desarrollar la economía social y solidaria en el
                    territorio.</p>
                <div className='buttonContainerInfNodess'>
                    <button className='bnInfNodess bnInfNodessB' onClick={handleButtonClicknosotros}>Ver más...</button>
                </div>
            </div>
        </section>
    )
}

const handleButtonClicknosotros = () => {
    window.location.href = '../Nosotros';
};

export default InfNodess